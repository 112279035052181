import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import SVG from 'react-inlinesvg';
import close from '../../static/close.svg';

const ImageGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const SingleImageLink = styled.a`
  text-decoration: none;
`;

const SingleImageWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 0.5s;

  &:hover {
    transition-duration: 0.5s;
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  }
`;

const StyledImage = styled(GatsbyImage)`
  position: fixed;
  width: 100%;
  height: 30rem;
  object-fit: cover;
`;

const LightboxModal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: ${(props) => (props.visible === true ? '1' : '0')};
  visibility: ${(props) => (props.visible === true ? 'visible' : 'hidden')};
  z-index: 9999;
  transition: opacity 0.5s linear;
`;

const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`;

const StyledCloseBtn = styled.div`
  width: 20px;
  height: 20px;
  top: 2%;
  right: 2%;
  position: fixed;
  cursor: pointer;
  opacity: 0.5;
  margin: 0;
  padding: 0;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: auto;
    margin: auto;
    display: block;
  }

  path {
    fill: white;
  }

  ${({ theme }) => theme.mq.desktop} {
    width: 40px;
    height: 40px;
    svg {
      width: 30px;
    }
  }
`;

const PreviousLink = styled.div`
  left: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 38px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};

  &:hover {
    opacity: 1;
  }

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem;
    font-size: 52px;
  }
`;

const NextLink = styled.div`
  right: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  position: fixed;
  font-size: 38px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  padding: 1rem;
  opacity: 0.5;
  transition: 0.3s;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};

  &:hover {
    opacity: 1;
  }

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem;
    font-size: 52px;
  }
`;

function SingleImage({ image }) {
  return (
    <SingleImageWrapper>
      <StyledImage
          image={image.childImageSharp.gatsbyImageData}
        alt={image.childImageSharp.id}
      />
    </SingleImageWrapper>
  );
}

const SmallGallery = ({ name }) => {
  const staticImages = useStaticQuery(graphql`{
  avant: allFile(filter: {relativePath: {regex: "/avant/"}}) {
    nodes {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`);

  const images = staticImages[name].nodes;

  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleClick = (e, index) => {
    e.preventDefault();
    setShowLightbox(!showLightbox);
    setSelectedImage(index);
  };

  const closeModal = () => {
    setShowLightbox(false);
  };

  const goBack = () => {
    setSelectedImage(selectedImage - 1);
  };

  const goForward = () => {
    setSelectedImage(selectedImage + 1);
  };

  useEffect(() => {
    const handleKeyUp = (e) => {
      e.preventDefault();

      const { keyCode } = e;
      if (showLightbox) {
        if (keyCode === 37) {
          // Left Arrow Key
          if (selectedImage > 0) {
            setSelectedImage(selectedImage - 1);
          }
        }
        if (keyCode === 39) {
          // Right Arrow Key
          if (selectedImage < images.length - 1) {
            setSelectedImage(selectedImage + 1);
          }
        }
        if (keyCode === 27) {
          // Escape key
          setShowLightbox(false);
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [showLightbox, selectedImage]);

  return <>
    <ImageGridStyles>
      {images.map((image, i) => (
        <SingleImageLink
          key={image.id}
          href={image.childImageSharp.gatsbyImageData.src}
          onClick={(e) => handleClick(e, i)}
        >
          <SingleImage image={image} />
        </SingleImageLink>
      ))}
    </ImageGridStyles>
    <LightboxModal visible={showLightbox}>
      <LightboxContent>
        <PreviousLink onClick={goBack} disabled={selectedImage === 0}>
          &#10094;
        </PreviousLink>
        <GatsbyImage
          image={images.length > 0 && images[selectedImage].childImageSharp.gatsbyImageData}
          alt={images.length > 0 && images[selectedImage].id} />
        <StyledCloseBtn onClick={closeModal}>
          <SVG src={close} />
        </StyledCloseBtn>
        <NextLink
          onClick={goForward}
          disabled={selectedImage === images.length - 1}
        >
          &#10095;
        </NextLink>
      </LightboxContent>
    </LightboxModal>
  </>;
};

export default SmallGallery;
