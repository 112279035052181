import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Hero from '../components/Hero';
import HeaderWithLine from '../components/HeaderWithLine';
import SEO from '../components/SEO';
import ContactFormCom from '../components/ContactFormCom';
import ImagesList from '../components/ImagesList';
import SmallGallery from '../components/SmallGallery';
import StyledButton from '../components/Button';

const OffersWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 2rem 2rem 2rem 0;

  ${({ theme }) => theme.mq.desktop} {
    padding: 2rem 6rem 2rem 0;
  }
`;

const OfferWrapper = styled.div`
  padding: 0 2rem;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    padding: 0;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const Anchor = styled.div`
  position: relative;

  & a {
    position: absolute;
    left: 0;
    top: -140px;
  }
`;

const StyledOffer = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    margin-left: 250px;
    max-width: 1200px;
  }

  .extraSpace {
    padding: 2rem 0;
  }
`;

const StyledHeaderWithLine = styled(HeaderWithLine)`
  &:before {
    margin-left: -150px;
    width: 250px;
  }

  ${({ theme }) => theme.mq.desktop} {
    margin-left: 150px;
  }
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    margin: 0 250px 8rem 250px;
  }
`;

const StyledList = styled.ul`
  line-height: 1.5;
  list-style-type: square;
`;

function getImagesByType(images, type) {
  return images
    .filter((img) =>
      img.galleryType.length > 0 ? img.galleryType[0].name === type : false
    )
    .map((img) => img.image)
    .slice(0, 3);
}

const WhiteButton = styled(StyledButton)`
  background-color: white;
`;

export default function OffersPage({ data }) {
  const projekty = getImagesByType(data.images.nodes, 'projekty');
  const realizacje = getImagesByType(data.images.nodes, 'realizacje');
  const pielegnacje = getImagesByType(data.images.nodes, 'pielęgnacje');

  return (
    <>
      <SEO
        title="Oferta - Projektowanie, urządzanie oraz pielęgnacje ogrodów"
        description="Oferujemy usługi ogrodnicze m.in. projektowanie, urządzanie oraz pielęgnacje ogrodów, instalacje oświetleniowe i nawadniające, zakładanie ogrodów, sadzenie roślin, pielęgnacja ogrodów, wynajem koparko - ładowarki AVANT."
      />
      <Hero image={data.singlePage.image} text={data.singlePage.description} />
      <OffersWrapper>
        <Anchor>
          <a id="projektowanie">&nbsp;</a>
        </Anchor>
        <OfferWrapper>
          <StyledHeaderWithLine>Projektowanie</StyledHeaderWithLine>
          <StyledOffer>
            Każda nowo zakładana zieleń wymaga dobrego pomysłu oraz
            zaprojektowania jej, po to aby w trakcie realizacji nie było zbyt
            wielu znaków zapytania, a jej wykonywanie było przeprowadzane
            sprawnie dzięki opisom i rysunkom zawartymi w każdym projekcie.
            Nasza firma wykonuje projekty we własnym zakresie m.in.:
            <StyledList>
              <li>projekty koncepcyjne w kolorze (widok z góry)</li>
              <li>oraz towarzyszące im wizualizacje 3D,</li>
            </StyledList>
            które bardziej obrazują widok przyszłego ogrodu.
            <div className="extraSpace">
              Ponadto wykonujemy{' '}
              <b>
                projekty techniczne całego zagospodarowania terenu począwszy od
                powierzchni brukowych, po instalacje oświetleniowe i
                nawadniające, kończąc na samej zieleni.
              </b>
            </div>
            {projekty.length > 0 ? <ImagesList images={projekty} /> : ''}
          </StyledOffer>
          <ButtonWrapper>
            <WhiteButton as={Link} to="/galeria/projekty">
              Zobacz nasze projekty
            </WhiteButton>
          </ButtonWrapper>
        </OfferWrapper>
        <Anchor>
          <a id="realizacja">&nbsp;</a>
        </Anchor>
        <OfferWrapper>
          <StyledHeaderWithLine>Urządzanie ogrodów</StyledHeaderWithLine>
          <StyledOffer>
            <div className="extraSpace">
              Po wykonaniu projektu zagospodarowania zieleni przychodzi kolejny
              etap – przeniesienie tego co na papierze lub komputerze w
              rzeczywistość. Jest to najciekawszy element całego założenia, gdy
              widać krok po kroku jak powstaje nowy ogród. Nasza firma zajmuje
              się kompleksowym wykonywaniem naszych jak i wykonanych przez inne
              osoby projektów.
            </div>
            <div>
              Oferujemy naszym klientom wykonywanie każdego etapu budowy ogrodu:
              <StyledList>
                <li>przygotowanie terenu,</li>
                <li>wyczyszczenie terenu,</li>
                <li>
                  zniwelowanie, wytyczenie wszystkich powstających elementów,
                </li>
                <li>montaż instalacji oświetleniowych,</li>
                <li>montaż instalacji nawadniających,</li>
                <li>montaż małej architektury,</li>
                <li>montaż powierzchni brukowych, tarasowych,</li>
                <li>sadzenie roślin,</li>
                <li>
                  wykańczanie rabat obrzeżami, agrowłókniną oraz ściółkami w
                  postaci kamieni ozdobnych jak i kory,
                </li>
                <li>zakładaniu trawników z rolki bądź też z siewu.</li>
              </StyledList>
            </div>
            <div className="extraSpace">
              Oferujemy w naszym zakresie usług również modernizację
              istniejących ogrodów jak i również wykonywanie zieleni na dachach
              budynków czy stropach dachowych garaży podziemnych oraz zielonych
              ścian wewnątrz i na zewnątrz budynków.
            </div>
            {realizacje.length > 0 ? <ImagesList images={realizacje} /> : ''}
          </StyledOffer>
          <ButtonWrapper>
            <WhiteButton as={Link} to="/galeria/realizacje">
              Zobacz nasze realizacje
            </WhiteButton>
          </ButtonWrapper>
        </OfferWrapper>
        <Anchor>
          <a id="pielegnacje">&nbsp;</a>
        </Anchor>
        <OfferWrapper>
          <StyledHeaderWithLine>PIELĘGNACJA OGRODÓW</StyledHeaderWithLine>
          <StyledOffer>
            <div className="extraSpace">
              Ostatnim etapem w zagospodarowaniu zieleni jest jej systematyczna
              pielęgnacja. Każdy nowopowstający lub zmodernizowany teren zieleni
              wymaga fachowej opieki. Oferujemy pełen zakres prac z tym
              związanych:
              <StyledList>
                <li>koszenie trawników wraz z wywiezieniem pokosu</li>
                <li>wykaszanie terenów kosą</li>
                <li>wertykulacja trawników</li>
                <li>renowacja zniszczonych i zaniedbanych trawników</li>
                <li>profesjonalne cięcie drzew, krzewów, bylin</li>
                <li>formowanie roślin oraz cięcie żywopłotów</li>
                <li>nawożenie wszystkich roślin w ogrodzie</li>
                <li>ochrona chemiczna, biologiczna roślin (opryski)</li>
                <li>odchwaszczanie ręczne i chemiczne i trawników</li>
                <li>wycinka drzew i krzewów</li>
                <li>grabienie liści jesienią i wiosną</li>
                <li>
                  przygotowywanie ogrodu do zimy tj. odpowiednie zabezpieczenie
                  roślin w ochronie przed mrozami
                </li>
                <li>serwisowanie systemów nawadniających</li>
                <li>
                  przedmuchiwanie oraz zamykanie systemów w okresie
                  jesienno-zimowym, uruchamianie systemu na wiosnę, kontrola
                  systemu w trakcie sezonu wegetacyjnego roślin
                </li>
                <li>
                  czyszczenie wszystkich nawierzchni w ogrodzie myjką
                  ciśnieniową oraz ochrona chemiczna przed wyrastaniem chwastów
                </li>
                <li>sadzenie oraz przesadzanie roślin</li>
                <li>
                  przerabianie istniejących ogrodów jak i urządzanie nowych
                  rabat oraz trawników
                </li>
                <li>czyszczenie zaniedbanych działek</li>
                <li>odśnieżanie posesji</li>
              </StyledList>
            </div>
            <div>
              Oferujemy naszym klientom pakiety abonamentowe z wizytami co
              tygodniowymi, co dwutygodniowymi, miesięcznymi jak i również
              jednorazowe wizyty. Wszystkie nasze prace wyceniamy indywidualnie
              oraz bezpłatnie z dojazdem do klienta.
            </div>
            {pielegnacje.length > 0 ? <ImagesList images={pielegnacje} /> : ''}
          </StyledOffer>
          <ButtonWrapper>
            <WhiteButton as={Link} to="/galeria/pielegnacje">
              Zobacz nasze pielęgnacje
            </WhiteButton>
          </ButtonWrapper>
        </OfferWrapper>
        <Anchor>
          <a id="avant">&nbsp;</a>
        </Anchor>
        <OfferWrapper>
          <StyledHeaderWithLine>AVANT</StyledHeaderWithLine>
          <StyledOffer>
            <div>
              Nasza firma w zakresie swoich usług posiada również wynajem MINI
              koparko – ładowarki AVANT z operatorem. Jest to bardzo znane wśród
              ogrodników narzędzie pacy ułatwiające wykonywanie wielu czynności
              podczas realizacji.
            </div>{' '}
            <div className="extraSpace">
              W Greenerze korzystamy z modelu 520, który bardzo dobrze radzi
              sobie zarówno w ogrodach jak i na dużych placach budowy. Dzięki
              swoim rozmiarom oraz mocy jest wstanie pracować w bardzo trudno
              dostępnych miejscach. Jego dużą zaletą jest jej wszechstronność.
              Na chwilę obecną firma dysponuje takimi przystawkami jak:
              <StyledList>
                <li>łyżka 4w1 tzw. Krokodyl</li>
                <li>glebogryzarka separacyjna</li>
                <li>kratownica do transportu palet itp.</li>
                <li>walec do ubijania ziemi po wysiewie nasion</li>
              </StyledList>
            </div>
            <SmallGallery name="avant" />
          </StyledOffer>
        </OfferWrapper>
      </OffersWrapper>
      <ContactFormCom />
    </>
  );
}

export const query = graphql`
  query SinglePageOffersQuery {
    singlePage: sanitySinglepage(name: { eq: "Oferta" }) {
      id
      name
      description
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    images: allSanityGallery {
      nodes {
        id
        name
        isAlbum
        slug {
          current
        }
        galleryType {
          id
          name
        }
        galleryImageType {
          id
          name
        }
        image {
          asset {
            id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
